.deck-table__cost-type {
  width: 30px;
  margin: 0 auto;
  display: block;
}

.deck-table__card-image {
  max-width: 250px;
  width: 100%;
  height: 350px;
}
td.deck-table__collection-cell {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
