.site-card-wrapper {
  margin: 10px;
}

[data-theme="dark"] .site-card-wrapper {
  background: #303030;
}

.title {
  float: left;
  color: white;
}

.header-container {
  display: flex;
  align-items: center;
}
